import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to user administration
 */
const UserAdminAPI = {
  removeUser(userId) {
    return axiosClient
      .delete(`/api/team/user?user_id=${userId}`)
      .then(res => res);
  },
  userAction(userData) {
    if (userData.isEdit) {
      return this.updateUser(userData);
    }

    return this.addUser(userData);
  },
  addUser(userData) {
    return axiosClient
      .post('/api/team/user', userData)
      .then(res => res);
  },
  updateUser(userData) {
    return axiosClient
      .put('/api/team/user', userData)
      .then(res => res);
  },
};

export default UserAdminAPI;
