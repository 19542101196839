<template>
  <section>
    <div
      v-if="canAddRole"
      class="buttons is-right"
    >
      <b-button
        type="is-primary"
        class="button-shadowed"
        @click="editRoleModal(false)"
      >
        CREATE NEW ROLE
      </b-button>
    </div>

    <b-table
      :data="roleList.list"
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
      :sort-icon="sortIcon"
      default-sort="name"
      is-fullwidth
    >
      <template slot-scope="props">
        <b-table-column
          field="name"
          label="Role"
          searchable
          sortable
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="isAdmin"
          label="Admin"
        >
          <span v-if="props.row.isAdmin">True</span>
          <span v-else>False</span>
        </b-table-column>

        <b-table-column
          field="identifiers"
          label="Permissions"
          width="35%"
        >
          {{ displayPermissions(props.row.identifiers) }}
        </b-table-column>

        <b-table-column
          field="id"
          custom-key="buttons"
        >
          <div
            v-if="props.row.teamId !== null"
            class="buttons is-centered"
          >
            <b-button
              type="is-text"
              icon-right="pencil-alt"
              icon-pack="fas"
              class="no-underline"
              @click="editRoleModal(props.row)"
            />

            <b-button
              type="is-text"
              class="is-danger is-inverted"
              icon-right="trash-alt"
              icon-pack="far"
              @click="removeRole(props.row)"
            />
          </div>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                pack="fas"
                icon="frown"
                size="x-large"
              />
            </p>
            <p>No roles match that filter criteria</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

import EditRoleForm from './forms/EditRoleForm';
import RoleAPI from '../api/modules/Role';
import { getRoleList } from '../services/rolesHelper';

export default {
  name: 'RoleList',
  data() {
    return {
      canAddRole: false,
      sortIcon: 'arrow-up',
      isLoading: true,
      roleList: {list: []},
      allRoles: {
        admin: [],
        dataSources: [],
      },
    };
  },
  created() {
    this.allRoles = getRoleList(this.loadConnectors());
    this.updateRoles(false);

    // permission check to go here
    this.canAddRole = true;
  },
  methods: {
    updateRoles(force) {
      this.isLoading = true;
      this.getRoles(force).then((roles) => {
        this.isLoading = false;
        const validRoles = [];
        roles.roles.forEach((role) => {
          if (this.displayPermissions(role.identifiers)) {
            validRoles.push(role);
          }
        });
        Vue.set(this.roleList, 'list', validRoles);
      });
    },
    editRoleModal(role) {
      const self = this;

      let isEdit = true;
      if (role === false) {
        role = {};
        isEdit = false;
      }

      Vue.prototype.addModal({
        parent: this,
        component: EditRoleForm,
        hasModalCard: true,
        canCancel: false,
        props: {
          role,
          isEdit,
        },
        events: {
          edited() {
            self.updateRoles(true);
          },
        },
      });
    },
    removeRole(role) {
      const self = this;

      Vue.prototype.addDialog({
        title: 'Deleting role',
        message: `Are you sure you want to <b>delete</b> this role : <b>${ role.name }</b>? This action cannot be undone.`,
        confirmText: 'Delete Role',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          RoleAPI.deleteRole(role.id).then((res) => {
            if (res.data.status && res.data.status === 'Success') {
              this.$buefy.toast.open({
                message: 'Role removed',
                type: 'is-success',
              });
              self.updateRoles(true);
            } else {
              this.$buefy.toast.open({
                message: res.error ? res.error : 'Error removing role',
                type: 'is-danger',
              });
            }
          });
        },
      });
    },
    displayPermissions(permissions) {
      const displayPermissions = [];

      if (permissions.length === 0) {
        return 'All';
      }

      for (const selectedPermission of permissions) {
        for (const role of this.allRoles.admin) {
          if (role.value === selectedPermission) {
            displayPermissions.push(role.label);
            break;
          }
        }

        for (const role of this.allRoles.dataSources) {
          if (role.value === selectedPermission) {
            displayPermissions.push(role.label);
            break;
          }
        }
      }

      return displayPermissions.join(', ');
    },
    ...mapActions('role', ['getRoles']),
  },
};
</script>
