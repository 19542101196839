<template>
  <form
    action=""
    @submit.prevent="saveUser()"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p
          v-if="isEdit"
          class="modal-card-title"
        >
          Edit user
        </p>
        <p
          v-else
          class="modal-card-title"
        >
          Invite a new user
        </p>
      </header>
      <section class="modal-card-body">
        <b-field grouped>
          <b-field
            label="First Name"
            expanded
          >
            <b-input
              v-model="currentUser.firstName"
              placeholder="Enter first name"
              required
            />
          </b-field>
          <b-field
            label="Last Name"
            expanded
          >
            <b-input
              v-model="currentUser.lastName"
              placeholder="Enter last name"
              required
            />
          </b-field>
        </b-field>

        <b-field label="Email">
          <b-input
            v-model="currentUser.email"
            type="email"
            placeholder="Enter the user's email"
            required
            :disabled="isEdit"
          />
        </b-field>

        <b-field
          v-if="canAccessRoles()"
          label="Role"
        >
          <b-select
            v-model="currentUser.roleId"
            placeholder="Select a role"
            required
          >
            <option
              v-for="role in roles"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons is-right">
          <b-button
            type="button"
            class="inverted-button"
            @click="closeForm()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="isEdit"
            class="is-primary button-shadowed checkmark-button"
            icon-left="check"
            native-type="submit"
          >
            Save
          </b-button>

          <b-button
            v-else
            class="is-primary button-shadowed checkmark-button"
            icon-left="check"
            native-type="submit"
          >
            Invite
          </b-button>
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserAdminAPI from '../../api/modules/UserAdmin';

/**
 * this.currentUser = mapped user object that we're editing
 * this.data = currently logged in user
 */
export default {
  name: 'EditUserForm',
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      currentUser: {},
      roles: [],
      isEdit: false,
      isLoading: false,
    };
  },
  mounted() {
    this.currentUser =  { ...this.user };
    this.isEdit = this.user.id !== undefined;

    if (!this.isEdit) {
      this.currentUser.roleId = null;
    }

    this.getRoles(false).then(() => {
      this.roles = this.getStoredRoles();
    });
  },
  methods: {
    closeForm() {
      this.$parent.close();
    },
    saveUser() {
      this.setAppLoadingState(true);

      const roleId = this.canAccessRoles() ?
        this.currentUser.roleId : this.roles[0].id;

      UserAdminAPI.userAction({
        isEdit: this.isEdit,
        first_name: this.currentUser.firstName,
        last_name: this.currentUser.lastName,
        new_user_email: this.currentUser.email,
        user_id: this.currentUser.id,
        role_id: roleId,
      }).then((res) => {
        if (res.data === undefined) {
          this.$parent.close();
          this.setAppLoadingState(false);
          return;
        }

        this.setAppLoadingState(false);

        if (res.data.error) {
          this.$buefy.toast.open({
            message: res.data.error,
            type: 'is-danger',
          });
        } else {
          this.$buefy.toast.open({
            message: 'User saved',
            type: 'is-success',
          });

          this.$emit('edited');
          this.$parent.close();
        }
      });
    },
    ...mapActions('role', ['getRoles']),
    ...mapGetters('role', ['getStoredRoles']),
  },
};
</script>

<style scoped>
  /* b-input:expanded */
  .field.is-expanded {
    margin-bottom: 0 !important;
  }
</style>
