<template>
  <section>
    <div
      class="buttons is-right"
    >
      <b-tooltip
        :label="canAddUser ? '' : 'Please upgrade your plan in the billing section to add a team member'"
      >
        <b-button
          type="is-primary button-shadowed"
          :disabled="!canAddUser"
          @click="editUserModal({name: '', email: '', roleId: ''})"
        >
          Invite New User
        </b-button>
      </b-tooltip>
    </div>

    <b-table
      :data="data.team.users"
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
      :sort-icon="sortIcon"
      default-sort="lastName"
      class="margin-bottom-10"
    >
      <template slot-scope="props">
        <b-table-column
          field="fullName"
          label="User Name"
          class="is-vcentered"
          searchable
          sortable
        >
          <figure class="image is-32x32 margin-right-10">
            <img
              v-if="props.row.image"
              :src="props.row.image"
              class="is-circular"
              :alt="props.row.email"
            >
          </figure>

          {{ props.row.firstName }} {{ props.row.lastName }}
        </b-table-column>

        <b-table-column
          field="email"
          label="Email"
          searchable
          sortable
        >
          {{ props.row.email }}
        </b-table-column>

        <b-table-column
          v-if="canAccessRoles()"
          field="roleName"
          label="Role"
          sortable
        >
          {{ props.row.roleName }}
        </b-table-column>

        <b-table-column
          field="id"
          custom-key="buttons"
        >
          <div
            v-if="props.row.email !== userData.user.email"
            class="buttons is-centered"
          >
            <b-button
              type="is-text"
              icon-right="pencil-alt"
              icon-pack="fas"
              class="no-underline edit-button"
              @click="editUserModal(props.row)"
            />

            <b-button
              type="is-text"
              class="is-danger delete-button"
              icon-right="trash-alt"
              icon-pack="far"
              @click="removeUser(props.row)"
            />
          </div>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                pack="fas"
                icon="frown"
                size="x-large"
              />
            </p>
            <p>No users match that filter criteria</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import UserAdminAPI from '../api/modules/UserAdmin';
import EditUserForm from './forms/EditUserForm';

export default {
  name: 'UserList',
  data() {
    return {
      canAddUser: false,
      sortIcon: 'arrow-up',
      isLoading: true,
      // teamList: {list: []},
    };
  },
  computed: {
    ...mapState('team', ['data']),
    ...mapState('user', {
      userData: 'data',
    }),
  },
  created() {
    this.updateUsers(false);
  },
  methods: {
    updatePlanUsage() {
      this.getBillingInfo(true).then(() => {
        const limit = this.getUserLimit();

        this.canAddUser = limit.totalUsers < limit.limit;
      });
    },
    editUserModal(user) {
      const self = this;

      Vue.prototype.addModal({
        parent: this,
        component: EditUserForm,
        hasModalCard: true,
        props: { user },
        canCancel: false,
        events: {
          edited() {
            self.updateUsers(true);
          },
        },
      });
    },
    removeUser(user) {
      const self = this;

      Vue.prototype.addDialog({
        title: 'Deleting user',
        message: `Are you sure you want to <b>delete</b> this user : <b>${ user.email }</b>? This action cannot be undone.`,
        confirmText: 'Delete User',
        type: 'is-danger',
        hasIcon: true,
        trapFocus: true,
        onConfirm() {
          UserAdminAPI.removeUser(user.id).then((res) => {
            if (res === undefined) {
              this.$buefy.toast.open({
                message: 'Error whilst removing user',
                type: 'is-danger',
              });
              return;
            }

            this.$buefy.toast.open({
              message: 'User removed from team',
              type: 'is-success',
            });

            self.updateUsers(true);
          });
        }
      });
    },
    updateUsers(force = false) {
      this.updatePlanUsage();
      this.isLoading = true;

      this.getTeamUsers(force)
        .then(() => {
          /* const teamList = team.users;
          for (const teamMember of teamList) {
            teamMember.fullName = teamMember.firstName + teamMember.lastName;
          }
*/
          this.isLoading = false;
          // Vue.set(this.teamList, 'list', teamList);
        })
        .catch(() => {
          // Vue.set(this.teamList, 'list', []);
          this.isLoading = false;
        });
    },
    ...mapActions('team', ['getTeamUsers']),
    ...mapActions('billing', ['getBillingInfo']),
    ...mapGetters('billing', ['getUserLimit']),
  },
};
</script>

<style scoped>

</style>
