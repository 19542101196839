<template>
  <div>
    <TeamDetails />

    <b-tabs
      v-if="(userHasPermission('edit_team') || userHasPermission('edit_role')) && canAccessRoles()"
      v-model="selectedTab"
      class="block"
    >
      <b-tab-item
        v-if="userHasPermission('edit_team')"
        label="Users"
      />
      <b-tab-item
        v-if="userHasPermission('edit_role')"
        label="Roles"
      />
    </b-tabs>

    <UserList
      v-if="selectedTab === 0 && userHasPermission('edit_team')"
    />

    <RoleList
      v-if="selectedTab === 1 && userHasPermission('edit_role') && canAccessRoles()"
    />
  </div>
</template>

<script>
import RoleList from "../components/RoleList";
import UserList from '../components/UserList';
import TeamDetails from '../components/TeamDetails';

export default {
  name: 'PageTeam',
  components: {
    RoleList,
    UserList,
    TeamDetails,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
};
</script>

<style scoped>

</style>
