<template>
  <section style="margin-bottom: 50px">
    <div class="columns is-vcentered is-desktop margin-top-4-rem">
      <div class="column is-half">
        <b-field
          label="Team"
          label-position="on-border"
        >
          <b-input
            v-model="data.team.name"
            :disabled="!userHasPermission('edit_team')"
            expanded
          />
        </b-field>
      </div>
      <div class="column is-half">
        <b-button
          v-if="userHasPermission('edit_team')"
          class="is-uppercase inverted-button"
          @click="updateTeam()"
        >
          Update
        </b-button>
      </div>
    </div>

    <div class="columns margin-top-5  is-desktop">
      <div class="column is-half">
        <b-field
          label="User"
          label-position="on-border"
          expanded
          disabled
        >
          <b-input
            v-model="data.user.name"
            disabled
          />
        </b-field>
      </div>
      <div
        v-if="canAccessRoles()"
        class="column is-half"
      >
        <b-field
          label="Role"
          label-position="on-border"
          expanded
          disabled
        >
          <b-input
            v-model="data.user.roleName"
            disabled
          />
        </b-field>
      </div>
    </div>

    <div class="columns margin-top-5 is-desktop">
      <div class="column is-half">
        <b-field
          label="Date of Creation"
          label-position="on-border"
          disabled
        >
          <b-input
            :value="data.team.createdAt | humanDate"
            class="is-primary-color"
            disabled
            icon="calendar-alt"
          />
        </b-field>
      </div>
      <div class="column is-half" />
    </div>
  </section>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import TeamAPI from '../api/modules/Team';

export default {
  name: 'TeamDetails',
  computed: {
    ...mapState('user', ['data']),
  },
  created() {
    this.getProfile(false);
  },
  methods: {
    updateTeam() {
      this.setAppLoadingState(true);

      TeamAPI.updateName({name: this.data.team.name}).then(() => {
        this.updateTeamName(this.data.team.name);

        this.$buefy.toast.open({
          message: 'Team name updated',
          type: 'is-success',
        });
      }).finally(() => {
        this.setAppLoadingState(false);
      });
    },
    ...mapActions('user', ['getProfile', 'updateTeamName']),
  },
};
</script>
