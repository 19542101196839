// base list, will have data sources added via the server config
const roleList = {
  admin: [
    {id: 0, label: 'Edit Team', value: 'edit_team'},
    {id: 1, label: 'Invite Users', value: 'edit_user'},
    {id: 2, label: 'Edit Roles', value: 'edit_role'},
    {id: 3, label: 'Manage Billing', value: 'edit_billing'},
  ],
};

export function getRoleList(dataSourceConnections) {
  roleList.dataSources = [];
  if (!dataSourceConnections) return roleList;
  for (const kindId of Object.keys(dataSourceConnections)) {
    const dataSource = dataSourceConnections[kindId];

    roleList.dataSources.push({
      id: kindId,
      label: dataSource.name,
      value: dataSource.permission,
    });
  }

  return roleList;
}
