<template>
  <form
    action=""
    @submit.prevent="saveRole()"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p
          v-if="isEdit"
          class="modal-card-title"
        >
          Edit Role
        </p>
        <p
          v-else
          class="modal-card-title"
        >
          Add a new role
        </p>
      </header>
      <section
        class="modal-card-body is-size-7"
        style="padding-top: 0"
      >
        <section>
          <b-field
            label="Name*"
          >
            <b-input
              v-model="editRole.name"
              placeholder="Enter the role name"
              required
            />
          </b-field>

          <b-switch
            v-model="editRole.isAdmin"
          >
            Admin
          </b-switch>
        </section>
        <section>
          <div
            class="level margin-top-20"
            style="margin-bottom: 0"
          >
            <h2 class="title level-left is-size-6">
              Admin Permissions
            </h2>

            <b-field class="level-right">
              <b-checkbox
                v-model="selectAllAdmin"
                :disabled="editRole.isAdmin"
              >
                Select All
              </b-checkbox>
            </b-field>
          </div>

          <b-field
            v-for="permission in permissions.admin"
            :key="permission.id"
            style="margin-bottom: 0.5rem"
          >
            <b-checkbox
              v-model="editRole.identifiers"
              :disabled="editRole.isAdmin"
              :native-value="permission.value"
            >
              {{ permission.label }}
            </b-checkbox>
          </b-field>
        </section>
        <section>
          <div
            class="level margin-top-20"
            style="margin-bottom: 0"
          >
            <h2 class="title level-left is-size-6">
              Data Sources Permissions
            </h2>

            <b-field class="level-right">
              <b-checkbox
                v-model="selectAllDataSources"
                :disabled="editRole.isAdmin"
              >
                Select All
              </b-checkbox>
            </b-field>
          </div>

          <b-field
            v-for="permission in permissions.dataSources"
            :key="permission.id"
            style="margin-bottom: 0.5rem"
          >
            <b-checkbox
              v-model="editRole.identifiers"
              :disabled="editRole.isAdmin"
              :native-value="permission.value"
            >
              {{ permission.label }}
            </b-checkbox>
          </b-field>
        </section>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons is-right">
          <b-button
            type="button"
            @click="closeForm()"
          >
            Cancel
          </b-button>
          <b-button
            native-type="submit"
            icon-left="check"
            class="is-primary button-shadowed checkmark-button"
          >
            Save
          </b-button>
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import RoleAPI from '../../api/modules/Role';
import { getRoleList } from '../../services/rolesHelper';

export default {
  name: 'EditRoleForm',
  props: {
    role: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      permissions: {
        admin: [],
        dataSources: [],
      },
      selectAllAdmin: false,
      selectAllDataSources: false,
      editRole: {
        name: '',
        isAdmin: false,
        identifiers: [],
      },
    };
  },
  watch: {
    'editRole.isAdmin': function (currentValue) {
      this.selectAllDataSources = currentValue;
      this.selectAllAdmin = currentValue;
    },
    'selectAllDataSources': function() {
      if (this.selectAllDataSources) {
        this.pushIdentifiers(this.permissions.dataSources);
      } else {
        this.clearIdentifiers(this.permissions.dataSources);
      }
    },
    'selectAllAdmin': function() {
      if (this.selectAllAdmin) {
        this.pushIdentifiers(this.permissions.admin);
      } else {
        this.clearIdentifiers(this.permissions.admin);
      }
    },
  },
  created() {
    this.permissions = getRoleList(this.loadConnectors());
  },
  mounted() {
    if (this.isEdit) {
      this.editRole = { ...this.role };
    }
  },
  methods: {
    closeForm() {
      this.$parent.close();
    },
    saveRole() {
      this.setAppLoadingState(true);
      const roleData = this.editRole;
      roleData.isEdit = this.isEdit;

      RoleAPI.roleAction(roleData).then((res) => {
        this.setAppLoadingState(false);

        if (res.data.error) {
          this.$buefy.toast.open({
            message: res.data.error,
            type: 'is-danger',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Role saved',
            type: 'is-success',
          });

          this.$emit('edited');
          this.$parent.close();
        }
      });
    },
    pushIdentifiers(identifiers) {
      for (const identifier of identifiers) {
        let identifierExists = false;

        for (const value of this.editRole.identifiers) {
          if (identifier.value === value) {
            identifierExists = true;
            break;
          }
        }

        if (!identifierExists) {
          this.editRole.identifiers.push(identifier.value)
        }
      }
    },
    clearIdentifiers(identifiers) {
      this.editRole.identifiers = this.editRole.identifiers.filter((value) => {
        let identifierExists = false;
        for (const identifier of identifiers) {
          if (identifier.value === value) {
            identifierExists = true;
            break;
          }
        }

        return !identifierExists;
      });

      // eslint-disable-next-line
      console.log(this.editRole.identifiers);
    },
    ...mapActions('user', ['getProfile']),
  },
};
</script>
